import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import ServicesDetail from '../components/Services/ServicesDetail'

export default class CreativeServices extends Component {
  render() {
    const serviceImage = require('../assets/images/rawpixel-574844-unsplash.jpg')
    const majapa = require('../assets/images/majapa.png')
    const sajja = require('../assets/images/sajja.jpg')
    return (
      <Layout location={this.props.location}>
        <ServicesDetail
          service="Creative Services"
          serviceCaption="YOUR BRAND IS ALL THAT MATTERS"
          serviceDocumentLink="https://drive.google.com/file/d/14ktdRLmYCbH8MVA9GaavZbV3i0SI6tk7/view?usp=sharing"
          serviceHighlight={[
            'Brand creation',
            'Content development',
            'Social media strategy',
            'User experience & interface design',
          ]}
          serviceTopDescription="In this connected day and age, online content is the first thing your customers will look for. For many businesses this is an after thought. At Bora this is brought to the foreground from the onset"
          serviceBottomDescription="Bora Growth Partners helps businesses overcome these challenges by offering a collaborative solution in developing and implementing their growth strategy, new Market Entry Intelligence and Market Progress Reporting."
          serviceImage={serviceImage}
          serviceSpecialists={[
            {
              name: 'Mohamed Majapa',
              title: 'Managing partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/mohamed-majapa-2a93a2b2/',
              twitter: 'https://twitter.com/Ti_Mj',
              image: majapa,
            },
            {
              name: 'Steven Sajja',
              title: 'Technology partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/stevensajja/',
              twitter: 'https://twitter.com/sajeezy',
              image: sajja,
            },
          ]}
        />
      </Layout>
    )
  }
}
